<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="d-flex mr-5 float-right align-items-center d-none d-lg-flex">
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto px-2">
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <notification-dropdown class="d-none d-lg-block mx-1 vr pl-1" /> -->
      <!-- <cart-dropdown class="d-none d-lg-block ml-1 pl-1 vr" /> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user mx-1"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name }}
            </p>
            <span class="user-status fw-bold text-warning">
              {{ userData.role }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="profile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>
<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown.vue";
// import CartDropdown from "@core/layouts/components/app-navbar/components/CartDropdown.vue";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useJwt from "@/auth/jwt/useJwt";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    AppBreadcrumb,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
    // CartDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: {
        name: "",
        status: "",
        email: "",
        role: "",
        status: "",
      },
    };
  },
  created() {
    this.userData = getUserData();
  },
  methods: {
    async logout() {
      // Removed token from server
      await this.$http.get("logout");

      // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userData");
      localStorage.removeItem("accessToken");

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },

    profile() {
      this.$router.replace("/profile");
    },
  },
};
</script>

<style scoped>
.child {
  margin: 0vw;
}

.vr {
  border-left: thin solid #dfe0eb;
}
</style>
